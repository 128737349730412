import * as React from 'react';
import { __ } from 'react-i18n';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import { rem } from 'polished';
import styled from '../../theme/styled-components';
import {
  FlexCol,
  prop,
  ZemplinTitle,
  ZemplinSelect as Select,
  StyledLink,
  Button,
} from 'eshop-defaults';
import { Link, withRouter } from 'react-router';
import API from '../../services/API';
import NormsSelect from './NormsSelect';
import SearchableSelect from './SearchableSelect';

const Norms = () => {
  const [id, setId] = React.useState(null);
  const [din, setDin] = React.useState('');
  const [stn, setStn] = React.useState('');
  const [iso, setIso] = React.useState('');
  const [uni, setUni] = React.useState('');
  const [desc, setDesc] = React.useState('');
  const [normUrl, setNormUrl] = React.useState('');

  const [normsData, setNormsData] = React.useState<any[]>([]);

  React.useEffect(() => {
    async function fetchData() {
      const fetchedNormsData = await API.loadNorms();
      if (fetchedNormsData) {
        setNormsData(prop(fetchedNormsData, 'norms'));
      }
    }

    fetchData();
  }, []);

  let dins: string[] = ['-'];
  let stns: string[] = ['-'];
  let isos: string[] = ['-'];
  let unis: string[] = ['-'];
  let descs: string[] = ['-'];
  if (normsData && normsData.length) {
    normsData.forEach((n: any) => {
      if (n.din && !dins.includes(n.din)) {
        dins.push(n.din);
      }
    });
    normsData.forEach((n: any) => {
      if (n.stn && !stns.includes(n.stn)) {
        stns.push(n.stn);
      }
    });
    normsData.forEach((n: any) => {
      if (n.iso && !isos.includes(n.iso)) {
        isos.push(n.iso);
      }
    });
    normsData.forEach((n: any) => {
      if (n.uni && !unis.includes(n.uni)) {
        unis.push(n.uni);
      }
    });
    normsData.forEach((n: any) => {
      if (n.description && !descs.includes(n.description)) {
        descs.push(n.description);
      }
    });
  }
  dins.sort();
  stns.sort();
  isos.sort();
  unis.sort();
  descs = descs.sort((a, b) => a.localeCompare(b));

  const handleFoundNorm = async (foundNorm: any) => {
    if (!foundNorm) {
      return;
    }
    setDin(foundNorm.din || '-');
    setStn(foundNorm.stn || '-');
    setIso(foundNorm.iso || '-');
    setUni(foundNorm.uni || '-');
    setDesc(foundNorm.description || '-');
    setId(foundNorm.id || null);
    await resolveNormsAttribUrl(foundNorm.id);
  };

  const handleDinChange = option => {
    const value = option.value;
    setDin(value);
    const foundNorm = normsData.find(n => n.din === value);
    handleFoundNorm(foundNorm);
  };

  const handleStnChange = option => {
    const value = option.value;
    setStn(value);
    const foundNorm = normsData.find(n => n.stn === value);
    handleFoundNorm(foundNorm);
  };

  const handleIsoChange = option => {
    const value = option.value;
    setIso(value);
    const foundNorm = normsData.find(n => n.iso === value);
    handleFoundNorm(foundNorm);
  };

  const handleDescChange = option => {
    const value = option.value;
    setDesc(value);
    const foundNorm = normsData.find(n => n.description === value);
    handleFoundNorm(foundNorm);
  };

  const resolveNormsAttribUrl = async normId => {
    const data = normId ? await API.loadNormAttribUrl({ id: normId }) : null;
    const attribUrl = prop(data, 'attribUrl');
    setNormUrl(attribUrl);
  };

  return (
    <>
      <Wrapper className="container container--wide">
        <MetaTags
          canonicalUrl={`${process.env.REACT_APP_BASE_URL}/prevodnik-noriem`}
          tags={{ title: __('Prevodník noriem') }}
        />
        <StyledZemplinTitle>{__('Prevodník noriem')}</StyledZemplinTitle>
        {normUrl && (
          <ShowProductsLink to={normUrl}>
            {__('Zobraziť všetky produkty s týmito normami')}
          </ShowProductsLink>
        )}
        <StyledGridWrapper>
          <SearchableSelect
            label={__('DIN')}
            options={dins.map(d => {
              return { value: d, name: d };
            })}
            // name="din"
            onChange={handleDinChange}
            value={din}
            id={'din'}
          />
          <SearchableSelect
            label={__('STN')}
            options={stns.map(d => {
              return { value: d, name: d };
            })}
            // name="stn"
            onChange={handleStnChange}
            value={stn}
            id={'stn'}
          />
          <SearchableSelect
            label={__('ISO')}
            options={isos.map(d => {
              return { value: d, name: d };
            })}
            // name="iso"
            onChange={handleIsoChange}
            value={iso}
            id={'iso'}
          />
        </StyledGridWrapper>
        <br />
        <SearchableSelect
          label={__('Popis')}
          options={descs.map(d => {
            return { value: d, name: d };
          })}
          name="description"
          onChange={handleDescChange}
          value={desc}
        />
        <TableLink to={'/prevodna-tabulka'}>
          {__('Zobraziť všetky hodnoty')}
        </TableLink>
      </Wrapper>
    </>
  );
};

const TableLink = styled(StyledLink)`
  margin-top: ${rem(24)};
`;

const ShowProductsLink = styled(StyledLink)`
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  padding: ${rem(8)} ${rem(32)};
  border-radius: ${rem(2)};
  width: fit-content;
  text-decoration: none;
  font-size: ${rem(14)};
  margin: 0 auto ${rem(24)};
`;

const StyledGridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  gap: ${rem(16)};
  text-align: left;

  ${({ theme }) => theme.mediab.l1050`
      grid-template-columns: repeat(1, 1fr);
  `}
`;

export const StyledZemplinTitle = styled(ZemplinTitle)`
  margin: 0 0 ${rem(24)};
  font-size: ${rem(30)};
  line-height: ${rem(38)};
  margin-bottom: ${rem(40)};
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 700;

  ${({ theme }) => theme.mediab.l925`
    margin: ${rem(32)} 0 margin-bottom: ${rem(16)};
    font-size: ${rem(24)};
    line-height:${rem(32)};
  `}
`;

const Wrapper = styled(FlexCol)`
  width: 100%;
  padding-top: ${rem(40)};
  margin-bottom: ${rem(80)} !important;
  max-width: ${rem(800)};
  text-align: center;

  ${({ theme }) => theme.mediab.l925`
      padding-top: ${rem(24)};
      margin-bottom: ${rem(32)} !important;
  `}
`;

export default Norms;
