import * as React from 'react';
import { __ } from 'react-i18n';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import { rem } from 'polished';
import styled from '../../theme/styled-components';
import {
  AddToCartModal,
  FlexCol,
  FlexRow,
  FlexRowCenter,
  FlexRowCenterVertical,
  prop,
  ZemplinTitle,
  ZemplinSelect as Select,
  StyledLink,
} from 'eshop-defaults';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import { Link, withRouter } from 'react-router';
import API from '../../services/API';
import { langSelector } from '../App/selectors';
import { Button } from 'eshop-defaults/lib/components/Zemplin/General/Button';
import { Loader } from 'eshop-defaults/lib/components/Zemplin/MyAccount/RequestDetail';
import {
  addItemToCartFromModal,
  addToCartModalProductSelector,
  addToCartModalVisibleSelector,
  cartDataSelector,
  setAddToCartModalVisibility,
} from '../Cart/cartSlice';
import NormsSelect from './NormsSelect';

const NormsTable = props => {
  const [din, setDin] = React.useState('');
  const [stn, setStn] = React.useState('');
  const [iso, setIso] = React.useState('');
  const [uni, setUni] = React.useState('');
  const [desc, setDesc] = React.useState('');

  const [normsData, setNormsData] = React.useState<any[]>([]);

  React.useEffect(() => {
    async function fetchData() {
      const fetchedNormsData = await API.loadNorms();
      if (fetchedNormsData) {
        setNormsData(prop(fetchedNormsData, 'norms'));
      }
    }

    fetchData();
  }, []);

  const dins: string[] = ['-'];
  const stns: string[] = ['-'];
  const isos: string[] = ['-'];
  const unis: string[] = ['-'];
  const descs: string[] = ['-'];
  if (normsData && normsData.length) {
    normsData.forEach((n: any) => {
      if (n.din && !dins.includes(n.din)) {
        dins.push(n.din);
      }
    });
    normsData.forEach((n: any) => {
      if (n.stn && !stns.includes(n.stn)) {
        stns.push(n.stn);
      }
    });
    normsData.forEach((n: any) => {
      if (n.iso && !isos.includes(n.iso)) {
        isos.push(n.iso);
      }
    });
    normsData.forEach((n: any) => {
      if (n.uni && !unis.includes(n.uni)) {
        unis.push(n.uni);
      }
    });
    normsData.forEach((n: any) => {
      if (n.description && !descs.includes(n.description)) {
        descs.push(n.description);
      }
    });
  }

  return (
    <>
      <Wrapper className="container container--wide">
        <MetaTags
          canonicalUrl={`${process.env.REACT_APP_BASE_URL}/prevodnik-noriem`}
          tags={{ title: __('Prevodník noriem') }}
        />
        <StyledZemplinTitle>{__('Prevodná tabuľka')}</StyledZemplinTitle>
        <TableLink to={'/prevodnik-noriem'}>
          {__('Filtrovať hodnoty')}
        </TableLink>
        <StyledTable>
          <StyledHead>
            <StyledTh>{__('DIN')}</StyledTh>
            <StyledTh>{__('STN 02')}</StyledTh>
            <StyledTh>{__('ISO')}</StyledTh>
            <StyledTh>{__('UNI')}</StyledTh>
            <StyledTh>{__('Popis')}</StyledTh>
          </StyledHead>
          <StyledTbody>
            {normsData &&
              normsData.map((n: any, i: number) => {
                return (
                  <StyledTr key={n.id}>
                    <StyledTd>{n.din || '-'}</StyledTd>
                    <StyledTd>{n.stn || '-'}</StyledTd>
                    <StyledTd>{n.iso || '-'}</StyledTd>
                    <StyledTd>{n.uni || '-'}</StyledTd>
                    <StyledTd>{n.description || '-'}</StyledTd>
                  </StyledTr>
                );
              })}
          </StyledTbody>
        </StyledTable>
      </Wrapper>
    </>
  );
};

const StyledTable = styled.table`
  width: 100%;
  margin: 0;
  padding: 0;
  border-collapse: separate;
  border-spacing: 0 0;
`;

const StyledHead = styled.thead``;

const StyledTh = styled.th`
  text-align: left;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 600;
  border-bottom: ${({ theme }) => theme.borders.primary};
  padding: ${rem(8)};
`;

const StyledTbody = styled.tbody``;

const StyledTr = styled.tr``;

const StyledTd = styled.td`
  text-align: left;
  border-bottom: ${({ theme }) => theme.borders.primary};
  padding: ${rem(8)};
  color: ${({ theme }) => theme.colors.textPrimary};
`;

const TableLink = styled(StyledLink)`
  margin-bottom: ${rem(40)};
`;

export const StyledZemplinTitle = styled(ZemplinTitle)`
  margin: 0 0 ${rem(24)};
  font-size: ${rem(30)};
  line-height: ${rem(38)};
  color: ${({ theme }) => theme.colors.textPrimary};
  font-weight: 700;

  ${({ theme }) => theme.mediab.l925`
    margin: ${rem(32)} 0 margin-bottom: ${rem(16)};
    font-size: ${rem(24)};
    line-height:${rem(32)};
  `}
`;

const Wrapper = styled(FlexCol)`
  width: 100%;
  padding-top: ${rem(40)};
  margin-bottom: ${rem(80)} !important;
  max-width: ${rem(800)};
  text-align: center;

  ${({ theme }) => theme.mediab.l925`
      padding-top: ${rem(24)};
      margin-bottom: ${rem(32)} !important;
  `}
`;

export default NormsTable;
