import * as React from 'react';
import { connect } from 'react-redux';
import { default as API, ThenArg } from '../../services/API';
import { langSelector, titleSelector } from '../Category/selectors';
import { userSelector, contactInfoSelector } from '../App/selectors';
import Product from '../../components/Product/Product';
import { connectSsr } from 'ssr-service';
import {
  loadProductDetail,
  loadProductDetailRelated,
  loadProductDetailAccessory,
} from './actions';
import {
  productDataSelector,
  productDetailIsFetchingSelector,
  productAccessoriesSelector,
  productAccessoriesIsFetchingSelector,
  productAlternativesIsFetchingSelector,
  productAlternativesSelector,
} from './selectors';
import { InfoText, FlexCol, ZemplinLoaderWrapper } from 'eshop-defaults';
import { __ } from 'react-i18n';
import { InfoType } from 'eshop-defaults/lib/components/Zemplin/General/InfoText';
import styled from 'styled-components';
import { rem } from 'polished';
import { cartDataSelector } from '../Cart/cartSlice';
import { prop } from '../../utilities';
import { USER_COOKIE } from 'react-auth';
import * as cookie from 'react-cookies';

export interface ProductProps {
  user: ThenArg<typeof API.tokeninfo>;
  productData: ThenArg<typeof API.loadProduct>;
  dispatch: any;
  isFetching: boolean;
  lang: string;
  params: {
    product_id: number | string;
    product_url: string;
  };
  alternativeProducts: ThenArg<typeof API.loadProductConnections>;
  isFetchingAlternatives: boolean;
  accessoriesProducts: ThenArg<typeof API.loadProductConnections>;
  isFetchingAccessories: boolean;
  cart: ThenArg<typeof API.getCart> | null;
}

class ProductContainer extends React.Component<ProductProps> {
  public static async getInitialProps(props) {
    const {
      params: { product_id: productId },
      product_id: productId2,
      dispatch,
      cookies,
      location,
    } = props;

    const { pathname } = location;

    // console.log('product initial props');

    const cookiesUserCookie = prop(cookies, `${USER_COOKIE}`);
    const userCookie =
      typeof cookies === 'undefined'
        ? cookie.load(USER_COOKIE)
        : cookiesUserCookie;
    // console.log('product detail has cookies: ', {
    //   hasCookies: !!cookies,
    //   userCookie,
    //   cookiesUserCookie,
    // });
    if (userCookie) {
      API.setToken(userCookie);
    } else {
      API.setToken('');
    }
    const product_id = productId || productId2;
    try {
      await Promise.all([
        dispatch(loadProductDetail(product_id, pathname)),
        dispatch(loadProductDetailRelated(product_id)),
        dispatch(loadProductDetailAccessory(product_id)),
      ]);
      return {
        props,
        isLoaded: true,
      };
    } catch (exp) {
      console.log(exp);
      return {
        isError: true,
      };
    }
  }

  // public metaHandler = lang => {
  //   const { product } = this.props;
  //   const productPublish = propByKey(product, 'publish', []).find(
  //     pp => pp.lang_id === lang,
  //   );
  //
  //   const metaTags = propByKey(productPublish, 'content.json_content.meta', {
  //     title: this.props.defaultTitle,
  //   });
  //
  //   if (!metaTags.title.length) {
  //     metaTags.title = `${this.props.defaultTitle} - ${product.name}`;
  //   }
  //
  //   return <MetaTags tags={metaTags} />;
  // };

  public addItemToCart = async (productId, goodId, count) => {
    // console.log('add');
  };

  public render() {
    const {
      productData,
      isFetching,
      alternativeProducts,
      isFetchingAlternatives,
      accessoriesProducts,
      isFetchingAccessories,
      dispatch,
      user,
      cart,
    } = this.props;

    if (isFetching) {
      return <ZemplinLoaderWrapper height={300} />;
    }

    if (!productData) {
      return (
        <Wrapper className="container container--wide">
          <InfoText info={__('Produkt nebol nájdený')} type={InfoType.ERROR} />
        </Wrapper>
      );
    }
    return (
      <Product
        product={productData}
        isFetching={isFetching}
        dispatch={dispatch}
        alternativeProducts={alternativeProducts}
        isFetchingAlternatives={isFetchingAlternatives}
        accessoriesProducts={accessoriesProducts}
        isFetchingAccessories={isFetchingAccessories}
        user={user}
        cart={cart}
      />
    );
  }
}

const Wrapper = styled(FlexCol)`
  padding: ${rem(56)} ${rem(24)} ${rem(80)};
  color: #444444;
  width: 100%;

  ${({ theme }) => theme.mediab.l1300`
      max-width: 930px;
  `}

  ${({ theme }) => theme.mediab.l925`
      padding: ${rem(32)} 0;
  `}
`;

const mapStateToProps = state => {
  return {
    user: userSelector(state),
    lang: langSelector(state),
    defaultTitle: titleSelector(state),
    contactInfo: contactInfoSelector(state),
    productData: productDataSelector(state),
    isFetching: productDetailIsFetchingSelector(state),
    alternativeProducts: productAlternativesSelector(state),
    isFetchingAlternatives: productAlternativesIsFetchingSelector(state),
    accessoriesProducts: productAccessoriesSelector(state),
    isFetchingAccessories: productAccessoriesIsFetchingSelector(state),
    cart: cartDataSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'ProductContainer' })(ProductContainer),
);
