import {
  REQUEST_CATEGORIES,
  RECEIVE_CATEGORIES_SUCCESS,
  RECEIVE_CATEGORIES_ERROR,
  REQUEST_CATEGORY_PRODUCTS,
  RECEIVE_CATEGORY_PRODUCTS_SUCCESS,
  RECEIVE_CATEGORY_PRODUCTS_ERROR,
  SET_CATEGORY_FILTER,
  SET_CATEGORY_FILTERS,
} from './constants';
import { prop } from '../../utilities';
import { langSelector, userSelector } from '../App/selectors';
import { categoryDataSelector } from './selectors';
import { resolveCurrentThemeFromCategory } from '../../utilities/category';
import { setCurrentTheme } from '../App/actions';
import { AppThunk } from '../../rootReducer';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';

export const setCategoryFilter = (sort, sortDir) => ({
  type: SET_CATEGORY_FILTER,
  payload: { sort, sortDir },
});

const requestCategoryProducts = () => ({
  type: REQUEST_CATEGORY_PRODUCTS,
});

const receiveCategoryProductsSuccess = products => ({
  type: RECEIVE_CATEGORY_PRODUCTS_SUCCESS,
  payload: {
    products,
  },
});

const receiveCategoryProductsError = error => ({
  type: RECEIVE_CATEGORY_PRODUCTS_ERROR,
  payload: {
    error,
  },
});

export const setCategoryInitialStateFromUrl = location => {
  return async dispatch => {
    const { query } = location;
    let offset = 0;
    let sort = '';
    let sortDir = '';
    if (query.offset) {
      if (isNaN(query.offset)) {
        offset = 0;
      } else {
        offset = Number(query.offset);
      }
    }
    if (query.sort) {
      sort = prop(query, 'sort', '');
    }
    if (query.sortDir) {
      sortDir = prop(query, 'sortDir', '');
    }
    dispatch(setCategoryFilters({ offset, pathname: location.pathname }));
    dispatch(setCategoryFilter(sort, sortDir));
  };
};

export const getNextCategoryUrl = (category, newOffset = 0, sort, sortDir) => {
  return {
    pathname: category.category.data.url,
    query: {
      offset:
        newOffset.toString() || category.category.products.products.offset,
      sort: sort || sort === '' ? sort : category.category.products.sort,
      sortDir:
        sortDir || sortDir === ''
          ? sortDir
          : category.category.products.sortDir,
    },
    state: {
      reload: false,
    },
  };
};

export const setCategoryFilters = filters => ({
  type: SET_CATEGORY_FILTERS,
  payload: {
    filters,
  },
});

export const loadCategoryProducts = (categoryId, offset): AppThunk => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestCategoryProducts());

      const lang: string = langSelector(getState());
      const products = await API.searchProducts(
        {
          categoryId,
          offset,
          limit: 12,
          sort: 'product_plu',
          sortDir: 'asc',
          // sort: prop(getState(), 'categoryDetail.category.products.sort'),
          // sortDir: prop(getState(), 'categoryDetail.category.products.sortDir'),
          withAttribs: '0',
          withGifts: '0',
          withBrand: '0',
          withProductPackages: '1',
        },
        { xAcceptLanguage: lang },
      );
      dispatch(receiveCategoryProductsSuccess(products));
    } catch (e) {
      dispatch(receiveCategoryProductsError(e));
    }
  };
};

export const loadCategorySpecialProducts = (pathname, offset): AppThunk => {
  return async (dispatch, getState, API) => {
    const user = userSelector(getState());
    const isB2B = user && user.b2b;
    const param = pathname.includes('novinky')
      ? 'isNew'
      : pathname.includes('akcie')
      ? isB2B
        ? 'isSaleB2B'
        : 'isSale'
      : 'isSaleout';
    try {
      dispatch(requestCategoryProducts());

      const lang: string = langSelector(getState());
      const products = await API.searchProducts(
        {
          offset,
          limit: 12,
          sort: 'product_plu',
          sortDir: 'asc',
          [param]: 1,
          // sort: prop(getState(), 'categoryDetail.category.products.sort'),
          // sortDir: prop(getState(), 'categoryDetail.category.products.sortDir'),
          withAttribs: '0',
          withGifts: '0',
          withBrand: '0',
          withProductPackages: '1',
          withCustomOrderNr: '1',
          goodsWithStores: '1',
          withParentCategories: '1',
          withFreeDelivery: '1',
        },
        { xAcceptLanguage: lang },
      );
      dispatch(receiveCategoryProductsSuccess(products));
    } catch (e) {
      dispatch(receiveCategoryProductsError(e));
    }
  };
};

const requestCategory = () => ({
  type: REQUEST_CATEGORIES,
});

const receiveCategorySuccess = category => ({
  type: RECEIVE_CATEGORIES_SUCCESS,
  payload: {
    category,
  },
});

const receiveCategoryError = error => ({
  type: RECEIVE_CATEGORIES_ERROR,
  payload: {
    error,
  },
});

export const loadCategory = (categoryId, pathname) => {
  return async (dispatch, getState, API) => {
    try {
      const currentCategory = categoryDataSelector(getState());
      let category = currentCategory;
      if (
        !category ||
        !category.category_id ||
        category.category_id.toString() !== categoryId
      ) {
        dispatch(requestCategory());
        category = await API.loadCategory(categoryId);
        dispatch(receiveCategorySuccess(category));
      }

      const { category_number, parent_categories } = category;
      const uniqueCatIds: string[] = [];
      uniqueCatIds.push(category_number);
      parent_categories.map(p => uniqueCatIds.push(p.category_number));
      const theme = resolveCurrentThemeFromCategory(uniqueCatIds);
      dispatch(setCurrentTheme(theme, pathname));

      dispatch(setBreadCrumbPath(BreadCrumbType.CATEGORY, category));
    } catch (e) {
      dispatch(receiveCategoryError(e));
    }
  };
};
