import {
  MetaTags,
  TopInfoBanner,
  ZemplinLoaderWrapper,
  AddToCartModal,
  prop,
  InfoText,
} from 'eshop-defaults';
import * as React from 'react';
import styled from 'styled-components';
import * as cookie from 'react-cookies';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import {
  loadFooterData,
  setCurrency,
  loadDefaultSettings,
  setLanguage,
  resetToken,
  setTopText,
} from './actions';
import { CURRENCY_COOKIE, DATALAYER_PRODUCTS_COOKIE } from './constants';
import {
  dimmerVisibilitySelector,
  currentThemeSelector,
  topTextSelector,
} from './selectors';
import Dimmer from '../../components/_helpers/Dimmer/Dimmer';
import { loadCategoriesTree } from '../Header/actions';
import Footer from '../Footer/Footer';
import { GlobalStyles } from '../../theme/globalStyles';
import { LogoMicrodata } from '@bart.sk-ecommerce/react-microdata';
// import { checksRedirect } from '@bart.sk-ecommerce/react-eshop-redirects/lib/action';
import { theme } from '../../theme/theme';
import ContainerHeader from '../Header/Header';
import { rem } from 'polished';
import { USER_COOKIE } from 'react-auth';
import { loginUser } from 'react-auth/lib/containers/Auth/actions';
import { ThemeProvider } from 'styled-components';
import {
  fetchCart,
  addToCartModalVisibleSelector,
  setAddToCartModalVisibility,
  addToCartModalProductSelector,
  addItemToCartFromModal,
  addToCartModalIsRequestSelector,
  addToCartModalIsFromCategorySelector,
  cartDataSelector,
} from '../Cart/cartSlice';
import AddedToCartModal from '../Cart/AddedToCartModal';
import ImportCartModal from '../Cart/ImportCartModal';
import BreadCrumbContainer from '../BreadCrumb/BreadCrumb';
import API from '../../services/API';
import {
  checksRedirect,
  isSSR,
} from '@bart.sk-ecommerce/react-eshop-redirects/lib/action';
import Cookies from '../../components/Cookies/Cookies';
import FacebookPagePlugin from '../../utilities/FacebookPagePlugin';
import { CART_COOKIE_ID } from '../Cart/helpers';
import AccountSelectWindow from '../../components/MyAccount/AccountSelectWindow';
import { __ } from 'react-i18n/lib';
import { InfoType } from 'eshop-defaults/lib/components/Zemplin/General/InfoText';
import { setNewDatalayerProducts } from '../../utilities/localStorage';

interface Props {
  user: any;
  lang: string;
  props: any;
  socialUrls: {
    fbUrl: string;
    igUrl: string;
    ytUrl: string;
  };
  metaTags: {
    description: string;
    keywords: string;
    og_article_author: string;
  };
  dispatch: any;
  menuItems: Array<{
    active: boolean;
    name: string;
    url: string;
    attribs: any;
  }>;
  isError: boolean;
  dimmerVisible: boolean;
  currentTheme: string;
  isLoaded: boolean;
  token: string;
  addToCartModal: boolean;
  addToCartModalProduct: any;
  addToCartModalIsRequest: boolean;
  addToCartIsFromCategory: boolean;
  topText: string;
  category: any;
  productDetail: any;
  cookies: any;
  cart: any;
}

export const FillSpaceWrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  min-height: ${rem(600)};

  ${() => theme.mediab.l925`
     min-height: ${rem(400)};
  `}
`;

class App extends React.Component<Props> {
  public static async getInitialProps(props) {
    const { dispatch, token, user, topText, cookies } = props;

    try {
      dispatch(setLanguage('sk'));
      // dispatch(setCurrency('EUR' || cookie.load(CURRENCY_COOKIE)));
      dispatch(setCurrency('EUR'));

      const cookiesAvailable = cookie.loadAll();
      const userCookie =
        typeof cookies === 'undefined'
          ? cookie.load(USER_COOKIE)
          : prop(cookies, `${USER_COOKIE}`);
      if (userCookie) {
        API.setToken(userCookie);
      } else {
        API.setToken('');
      }

      if (!token || !user) {
        if (userCookie) {
          await dispatch(loginUser(userCookie));
        } else if (
          prop(cookiesAvailable, 'cart_id') ||
          prop(cookiesAvailable, 'cc_cookie')
        ) {
          await dispatch(resetToken());
        }
        // if (process.env.NODE_ENV === 'development') {
        //   await dispatch(
        //     loginUser(
        //       '2b17aa2a35ba7969cde7ce0d98d82c49d254b3451c466d69cd3a011a8b53a15cdf197c2c7096f4d400b47cb3eb27cfc2224742375538e60fde26664bc274dff7859d2c92c2a49558c31030b09a6f347636860056299adf82c2efce7fbc3dc769437231bb91f20e7865d46d139b8e8ab3a98c16ef623dc405d3ac204a09f92a8e',
        //     ),
        //   );
        // }
      }

      if (!topText) {
        const text = await API.loadOtherTexts('HEADER');
        await dispatch(setTopText(prop(text, 'content.json_content.body')));
      }

      try {
        await props.dispatch(checksRedirect(props.location));
      } catch (e) {
        // silence is marvelous
      }

      const cartCookie = prop(cookies, `${CART_COOKIE_ID}`);
      await Promise.all([
        dispatch(loadDefaultSettings()),
        dispatch(loadCategoriesTree()),
        dispatch(loadFooterData()),
        dispatch(fetchCart(true, cartCookie)),
      ]);
      return {
        props,
        isLoaded: true,
      };
    } catch (exp) {
      return {
        isError: true,
        isLoaded: true,
      };
    }
  }

  public render() {
    const {
      children,
      dimmerVisible,
      socialUrls,
      user,
      currentTheme,
      isLoaded,
      token,
      dispatch,
      addToCartModal,
      addToCartModalProduct,
      addToCartModalIsRequest,
      addToCartIsFromCategory,
      topText,
      category,
      productDetail,
      lang,
      cart,
    } = this.props;

    const currentThemeColors =
      !currentTheme || !theme[currentTheme]
        ? {
            primary: theme.colors.primary,
            navSecondary: theme.colors.navSecondary,
            paginationSecondary: theme.colors.paginationSecondary,
          }
        : {
            primary: theme[currentTheme].primary,
            navSecondary: theme[currentTheme].navSecondary,
            paginationSecondary: theme[currentTheme].paginationSecondary,
          };

    const currentThemeColorsFilters =
      !currentTheme || !theme[currentTheme]
        ? {
            color: theme.colors.primary,
            activeBgColor: theme.colors.primary,
            sliderTrackColor: theme.colors.primary,
            trackBorderColor: theme.colors.primary,
          }
        : {
            color: theme[currentTheme].primary,
            activeBgColor: theme[currentTheme].primary,
            sliderTrackColor: theme[currentTheme].primary,
            trackBorderColor: theme[currentTheme].primary,
          };
    const vtUrls = [
      '/novinky',
      '/aktuality',
      '/akcie',
      '/vypredaj',
      '/kontakt',
      '/tipy-a-rady',
    ];
    const url = window?.location?.pathname;
    let parentCategories;
    const categoryId =
      category?.data && category.data.categoryInfo?.category_id
        ? category.data.categoryInfo.category_id
        : null;

    if (category?.data && category.data.categoryInfo?.parent_categories) {
      parentCategories = category.data.categoryInfo.parent_categories;
    } else if (
      productDetail?.productDetail &&
      productDetail?.productDetail.parent_categories
    ) {
      parentCategories = productDetail?.productDetail.parent_categories;
    }

    let isVt = true;

    if (!parentCategories?.length && categoryId && categoryId !== 2) {
      isVt = false;
    } else if (
      parentCategories?.length &&
      parentCategories[0].category_id !== 2
    ) {
      isVt = false;
    } else {
      isVt = true;
    }

    if ((url && url === '/') || vtUrls.includes(url)) {
      isVt = true;
    }

    const cartItems = prop(cart, 'items', []) || [];
    const cartItemsIds: any = cartItems
      ? cartItems.map(c => prop(c, 'good.good_id'))
      : [];

    let dataLayerProducts: any = null;
    try {
      dataLayerProducts = localStorage.getItem(DATALAYER_PRODUCTS_COOKIE)
        ? JSON.parse(localStorage.getItem(DATALAYER_PRODUCTS_COOKIE) as any)
        : '';
    } catch (error) {
      // silent
    }

    return (
      <React.Fragment>
        <Helmet />
        <LogoMicrodata
          url={process.env.REACT_APP_BASE_URL as string}
          logo={`${process.env
            .REACT_APP_BASE_URL as string}/images/zemplin/zemplin28.svg`}
        />

        <ThemeProvider
          theme={{
            ...theme,
            colors: {
              ...theme.colors,
              ...currentThemeColors,
            },
            categoryFilter: {
              ...theme.categoryFilter,
              ...currentThemeColorsFilters,
            },
          }}
        >
          <GlobalStyles />
          {this.props.metaTags && (
            <MetaTags
              tags={{
                ...this.props.metaTags,
                themeColor: currentThemeColors.primary,
              }}
            />
          )}

          {!!prop(user, 'b2b') && token ? (
            <TopInfoBanner
              colorGreen={false}
              message={topText ? topText : ''}
            />
          ) : null}
          {user && user.original_user && user.original_user.email ? (
            <TopInfoBanner
              colorGreen={true}
              message={`Ste prihlásený ako ${user ? user.firm : ''}`}
            />
          ) : null}
          <ContainerHeader />
          <BreadCrumbContainer />
          <FacebookPagePlugin isVt={isVt} />
          <FillSpaceWrapper>
            {!isLoaded ? <ZemplinLoaderWrapper height={300} /> : children}
          </FillSpaceWrapper>
          <Footer socialUrls={socialUrls} isVt={isVt} />
          {dimmerVisible && <Dimmer height={null} />}
          {addToCartModal && addToCartModalProduct && (
            <AddToCartModal
              product={addToCartModalProduct}
              handleClose={() => dispatch(setAddToCartModalVisibility())}
              isRequest={addToCartModalIsRequest}
              handleButtonClick={count => {
                const product = addToCartModalProduct;
                const key = `${prop(product, 'product_id')}-${prop(
                  product,
                  'main_good.good_id',
                ) || prop(product, 'product_detail.main_good.good_id')}`;
                if (!dataLayerProducts) {
                  dataLayerProducts = {};
                }
                const alreadyPushedItem = dataLayerProducts[key];
                if (!prop(alreadyPushedItem, 'isLocked')) {
                  dataLayerProducts[key] = {
                    item_list_name: prop(product, 'item_list_name'),
                    category:
                      prop(product, 'parent_categories.0.category_name') ||
                      prop(product, 'categories_names.0') ||
                      '',
                    category2:
                      prop(product, 'parent_categories.1.category_name') ||
                      prop(product, 'categories_names.1') ||
                      '',
                    category3:
                      prop(product, 'parent_categories.2.category_name') ||
                      prop(product, 'categories_names.2') ||
                      '',
                    category4:
                      prop(product, 'parent_categories.3.category_name') ||
                      prop(product, 'categories_names.3') ||
                      '',
                    category5:
                      prop(product, 'parent_categories.4.category_name') ||
                      prop(product, 'categories_names.4') ||
                      '',
                    isLocked: true,
                  };
                }
                setNewDatalayerProducts(dataLayerProducts);
                dispatch(
                  addItemToCartFromModal(parseFloat(count), dataLayerProducts),
                );
                if (
                  prop(addToCartModalProduct, 'isQuickBuy') &&
                  prop(addToCartModalProduct, 'handleQuickBuyAdd')
                ) {
                  prop(addToCartModalProduct, 'handleQuickBuyAdd')();
                }
              }}
              isLoggedIn={user && user.b2b ? true : false}
              isFromCategory={addToCartIsFromCategory}
              user={user}
              isQuickBuy={prop(addToCartModalProduct, 'isQuickBuy')}
              isInCart={cartItemsIds.includes(
                prop(
                  addToCartModalProduct,
                  'product_detail.main_good.good_id',
                ) || prop(addToCartModalProduct, 'main_good.good_id'),
              )}
              isOrdered={prop(addToCartModalProduct, 'isOrdered')}
            />
          )}

          {!user || !user.b2b ? <AddedToCartModal dispatch={dispatch} /> : null}
          {!!prop(user, 'b2b') && <ImportCartModal dispatch={dispatch} />}

          {user && user.customer_type === 'OZ' ? (
            <AccountSelectWindow
              dispatch={dispatch}
              user={user}
              lang={lang}
              token={token}
            />
          ) : null}
        </ThemeProvider>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    token: state.auth.token,
    socialUrls: state.general.socialUrls,
    metaTags: state.general.metaTags,
    lang: state.general.lang,
    dimmerVisible: dimmerVisibilitySelector(state),
    currentTheme: currentThemeSelector(state),
    addToCartModalProduct: addToCartModalProductSelector(state),
    addToCartModal: addToCartModalVisibleSelector(state),
    addToCartModalIsRequest: addToCartModalIsRequestSelector(state),
    addToCartIsFromCategory: addToCartModalIsFromCategorySelector(state),
    topText: topTextSelector(state),
    category: state.category,
    productDetail: state.productDetail,
    cart: cartDataSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'App' })(App),
);
