import * as React from 'react';
import { connect } from 'react-redux';
import InfoBanner from '../../components/_helpers/Default/InfoBanner';
import { connectSsr } from 'ssr-service';
import { withRouter, WithRouterProps } from 'react-router';
import LazyLoad from 'react-lazyload';
import CartLogic from './CartLogic';
import {
  cartDataSelector,
  cartIsFetchingSelector,
  cartErrorSelector,
  updateCart,
  fetchCart,
  cartShowProblemsSelector,
  cartZoneIdSelector,
  setCartError,
  emptyCart,
  setCreateOrderErrorState,
} from './cartSlice';
import API, { ThenArg } from '../../services/API';
import {
  deliveryAddressesIsFetchingSelector,
  deliveryAddressesSelector,
  fetchDeliveryAddresses,
} from '../MyAccount/myAccountSlice';
import { userSelector } from '../App/selectors';
import { prop } from '../../utilities';
import { CART_COOKIE_ID } from './helpers';

interface Props {
  dispatch: (action: any) => void;
  children: any;
  user: any;
  data: any;
  error: { details: { description: string } };
  deliveryAddresses: ThenArg<typeof API.getDeliveryAddresses>;
  isFetching: boolean;
  deliveryAddressesIsFetching: boolean;
  showProblems: boolean;
  zoneId: number;
  cookies: any;
  createOrderError?: string;
}

class Cart extends React.Component<Props & WithRouterProps> {
  public static async getInitialProps(props) {
    const { dispatch, cookies } = props;
    try {
      const cartCookie = prop(cookies, `${CART_COOKIE_ID}`);
      if (props.user) {
        await Promise.all([
          dispatch(fetchDeliveryAddresses()),
          dispatch(fetchCart(false, cartCookie)),
        ]);
      } else {
        await dispatch(fetchCart(false, cartCookie));
      }
      return;
    } catch (exp) {
      console.log(exp);
      return;
    }
  }

  public render() {
    const {
      children,
      data,
      error,
      deliveryAddresses,
      isFetching,
      deliveryAddressesIsFetching,
      dispatch,
      showProblems,
      user,
      zoneId,
      createOrderError,
    } = this.props;
    return (
      <>
        <CartLogic
          key={1}
          handleAcceptTermsChange={this.handleAcceptTermsChange}
          createOrder={this.createOrder}
          dispatch={dispatch}
          data={data}
          isFetching={isFetching}
          error={error}
          deliveryAddresses={deliveryAddresses}
          deliveryAddressesIsFetching={deliveryAddressesIsFetching}
          showProblems={showProblems}
          user={user}
          zoneId={zoneId}
          handleEmptyCartClick={this.handleEmptyCartClick}
          createOrderError={createOrderError || ''}
        >
          {children}
        </CartLogic>
        <InfoBanner />
      </>
    );
  }

  private handleEmptyCartClick = () => {
    this.props.dispatch(emptyCart());
  };

  private handleAcceptTermsChange = e => {
    this.props.dispatch(
      updateCart({ ...this.props.data, terms_accept: e.target.value }),
    );
  };

  private createOrder = async (): Promise<string> => {
    const { dispatch } = this.props;
    let orderPublicId = '';

    try {
      const res = await API.createOrder(
        this.props.data.id,
        {},
        { ...this.props.data, step: 3 },
      );
      orderPublicId = res.orderPublicId;
    } catch (err) {
      console.log({ err });
      if (
        err &&
        err.details.payload &&
        err.details.payload &&
        err.details.payload.cartProblems
      ) {
        dispatch(setCartError(err.details.payload.cartProblems));
      } else if (prop(err, 'details.name') === 'ORDERING_DISABLED') {
        dispatch(setCreateOrderErrorState(prop(err, 'details.description')));
      }
    }

    return orderPublicId;
  };
}

const mapStateToProps = state => {
  return {
    user: userSelector(state),
    data: cartDataSelector(state),
    isFetching: cartIsFetchingSelector(state),
    error: cartErrorSelector(state),
    createOrderError: prop(state, 'cart.data.createOrderError'),
    deliveryAddresses: deliveryAddressesSelector(state),
    deliveryAddressesIsFetching: deliveryAddressesIsFetchingSelector(state),
    showProblems: cartShowProblemsSelector(state),
    zoneId: cartZoneIdSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'Cart' })(withRouter(Cart)),
);
