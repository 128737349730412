import * as React from 'react';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  MetaTags,
  ZemplinSelect as Select,
  ZemplinCheckbox as CheckBox,
  FlexRowCenterVertical,
} from 'eshop-defaults';
import {
  fetchChildUsers,
  fetchRequests,
  requestsDataSelector,
  requestsByIdSelector,
  requestsIsFetchingSelector,
  childUsersSelector,
  fetchBackorders,
  backordersDataSelector,
  backordersByIdSelector,
  backordersIsFetchingSelector,
} from './myAccountSlice';
import API, { ThenArg } from '../../services/API';
import MyRequests from '../../components/MyAccount/MyRequests';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../BreadCrumb/breadCrumbSlice';
import MyBackorder from '../../components/MyAccount/MyBackorder';

const LIMIT = 10;

interface Props {
  user: any;
  dispatch: any;
  isFetching: boolean;
  token: string;
  childUsers: ThenArg<typeof API.loadChildUsers>['customers'];
  backordersData: any;
  backordersById: object;
}

interface State {
  currentId: string;
  isDownloading: boolean;
}

class MyBackorderContainer extends React.Component<Props, State> {
  public static async getInitialProps(props) {
    const { dispatch } = props;
    try {
      dispatch(setBreadCrumbPath(BreadCrumbType.MY_BACKORDER));
      await Promise.all([
        dispatch(fetchChildUsers()),
        dispatch(fetchBackorders(null, LIMIT, 0)),
      ]);
      return;
    } catch (exp) {
      console.log(exp);
      return;
    }
  }

  public constructor(props) {
    super(props);

    this.state = {
      currentId: props.user.id,
      isDownloading: false,
    };
  }

  public downloadFile = (order: any, type?: string) => {
    return;
  };

  public render() {
    const {
      user,
      childUsers,
      isFetching,
      backordersData,
      backordersById,
    } = this.props;
    const { currentId, isDownloading } = this.state;

    const childUserOptions = childUsers
      ? childUsers
          .map(user => ({
            name: `${user.meno} ${user.priezvisko}`,
            value: (user.id || '').toString(),
          }))
          .map(a => a)
      : [];
    const options = [
      { name: 'Ja', value: user.id.toString() },
      ...childUserOptions,
    ];

    return (
      <>
        <MetaTags tags={{ title: 'Moj účet - Nedodaný tovar' }} />
        <MyBackorder
          backorders={backordersById[currentId]}
          backordersData={backordersData}
          options={options}
          currentId={currentId}
          handleOffsetChange={this.handleOffsetChange}
          handleSelectChange={this.handleSelectChange}
          isFetching={isFetching}
          downloadFile={this.downloadFile}
          isDownloading={isDownloading}
          user={user}
        />
      </>
    );
  }

  public handleSelectChange = e => {
    const id = e.target.value;

    this.setState({ currentId: id });
    this.props.dispatch(fetchBackorders(id, LIMIT, 0));
  };

  public handleOffsetChange = e => {
    const { currentId } = this.state;
    const num = parseInt(e.target.text, 10) - 1;
    const offset = LIMIT * num;
    this.props.dispatch(fetchBackorders(currentId, LIMIT, offset));
  };
}

const FilterWrapper = styled(FlexRowCenterVertical)`
  ${({ theme }) => theme.mediab.l925`
    flex-flow: column;
    align-items: flex-start;
  `}
`;

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    isFetching: backordersIsFetchingSelector(state),
    childUsers: childUsersSelector(state),
    backordersData: backordersDataSelector(state),
    backordersById: backordersByIdSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'MyBackorder' })(MyBackorderContainer),
);
