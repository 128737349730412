import { AppThunk, RootState } from '../../rootReducer';
import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import API from '../../services/API';
import {
  ErrorTypeAPI,
  startFetching,
  stopFetching,
} from '../../utilities/redux';
import { prop } from '../../utilities';
import { langSelector, userSelector } from '../App/selectors';
import { resolveCurrentThemeFromCategory } from '../../utilities/category';
import { setCurrentTheme } from '../App/actions';
import { getCookie } from 'react-use-cookie';

export const hiddenAttribsByCategory = {
  SPOJ_MATERIAL: [],
  CENTRUM_VYKUROV: [
    'Rozmer',
    'Palivo',
    'Energetická trieda',
    'Rozmery (v x š x h) [mm]',
    'Min. výkon v kW [vzduch]',
    'Vykon vzduch nominalny',
  ],
  PRE_DREVAROV: ['Povrchová úprava'],
  OSTATNE: ['Rozmer', 'Dĺžka'],
};

export const hiddenAttribsInSpecsByCategory = {
  SPOJ_MATERIAL: [],
  CENTRUM_VYKUROV: ['Rozmer'],
  PRE_DREVAROV: [],
  OSTATNE: [],
};

interface CategoryState {
  data: any;
  error: ErrorTypeAPI;
  isFetching: boolean;
}

const initialState: CategoryState = {
  data: null,
  isFetching: false,
  error: null,
};

const category = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setInitialCategory(state) {
      state.data = null;
      state.error = null;
      stopFetching(state, '');
    },
    startFetchingCategory(state) {
      startFetching(state, '');
    },
    fetchCategorySuccess(
      state,
      action: PayloadAction<{
        category: any;
      }>,
    ) {
      state.data = action.payload.category;
      state.error = null;
      stopFetching(state, '');
    },
    fetchCategoryError(state, action: PayloadAction<ErrorTypeAPI>) {
      // state.data = null;
      state.error = action.payload;
      stopFetching(state, '');
    },
  },
});

const {
  startFetchingCategory,
  fetchCategorySuccess,
  fetchCategoryError,
} = category.actions;
export default category.reducer;

const categoryDomainSelector = (state: RootState) => state.category;

export const categoryIsFetchingSelector = createSelector(
  categoryDomainSelector,
  substate => prop(substate, 'isFetching', false),
);

export const categoryInfoSelector = createSelector(
  categoryDomainSelector,
  substate => prop(substate, 'data.categoryInfo', null),
);

export const categoryProductsSelector = createSelector(
  categoryDomainSelector,
  substate => prop(substate, 'data.products', null),
);

export const categoryChildrenCategoriesSelector = createSelector(
  categoryDomainSelector,
  substate => prop(substate, 'data.subTree', []),
);

export const categoryFilterDataSelector = createSelector(
  categoryDomainSelector,
  substate => ({
    minPrice: prop(substate, 'data.minPrice', null),
    maxPrice: prop(substate, 'data.maxPrice', null),
    attribs: prop(substate, 'data.attribsAggs', null),
  }),
);

export const fetchCategory = (
  categoryId: number,
  filters?: any,
  isSearch = false,
  pathname?: string,
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(startFetchingCategory());

    const user = userSelector(getState());
    const searchOnlyInCodes =
      isSearch && !!user && getCookie('search_only_in_codes') === '1';
    const category: any = await API.loadElasticCategoryProducts(categoryId, {
      langId: langSelector(getState()),
      onlySubcategories: '0',
      limit: filters.productCount ? filters.productCount : 12,
      ...filters,
      ...filters.otherAttribs,
      searchOnlyCustomOrdernr: searchOnlyInCodes ? 1 : 0,
    });
    dispatch(fetchCategorySuccess({ category }));

    if (category && category.categoryInfo) {
      const {
        categoryInfo: { category_number, parent_categories },
      } = category;
      const uniqueCatIds: string[] = [];
      uniqueCatIds.push(category_number);
      parent_categories.map(p => uniqueCatIds.push(p.category_number));
      const theme = resolveCurrentThemeFromCategory(uniqueCatIds);
      dispatch(setCurrentTheme(theme, pathname));
    }
  } catch (err) {
    dispatch(fetchCategoryError(err));
  }
};

export const attribsObjectToUrl = (catUrl: string, urlAttribs: any) => {
  const {
    min,
    max,
    attribs,
    rangeAttribs,
    sort,
    sortDir,
    otherAttribs,
    q,
  } = urlAttribs;
  let paramsString = '';
  const sortString = sort && sortDir ? `sort=${sort}&sortDir=${sortDir}` : '';
  paramsString += sortString;

  let attribsString = '';
  if (attribs && Object.keys(attribs).length > 0) {
    attribsString = paramsString ? '&attribs=' : 'attribs=';
    Object.keys(attribs).map(key => {
      const attrib = attribs[key];
      attrib.values.map(attrib => {
        attribsString += `${key}|${attrib},`;
      });
    });
    attribsString = attribsString.substring(0, attribsString.length - 1);
  }
  paramsString += attribsString;

  let rangeAttribsString = '';
  if (rangeAttribs && Object.keys(rangeAttribs).length > 0) {
    rangeAttribsString = paramsString ? '&range_attribs=' : 'range_attribs=';
    Object.keys(rangeAttribs).map(key => {
      const attrib = rangeAttribs[key];
      rangeAttribsString += `${key}|${attrib.min}-${attrib.max},`;
    });
    rangeAttribsString = rangeAttribsString.substring(
      0,
      rangeAttribsString.length - 1,
    );
  }
  paramsString += rangeAttribsString;

  let minString = '';
  if (min) {
    minString = paramsString ? `&min=${min}` : `min=${min}`;
  }
  paramsString += minString;

  let maxString = '';
  if (max) {
    maxString = paramsString ? `&max=${max}` : `max=${max}`;
  }
  paramsString += maxString;

  let otherAttribsString = '';
  if (otherAttribs) {
    Object.keys(otherAttribs).map(key => {
      otherAttribsString +=
        paramsString || otherAttribsString
          ? `&${key}=${otherAttribs[key]}`
          : `${key}=${otherAttribs[key]}`;
    });
  }
  paramsString += otherAttribsString;

  if (q) {
    paramsString += paramsString ? `&q=${q}` : `q=${q}`;
  }

  return `${catUrl}?${paramsString}`;
};

export const urlAttribsToObject = (urlAttribs: any) => {
  let attribsObj;
  const urlAttribFilters = prop(urlAttribs, 'attribs');
  if (urlAttribFilters) {
    attribsObj = {};
    const attribs = urlAttribFilters.split(',');
    attribs.map(attrib => {
      const [attribId, valueId] = attrib.split('|');
      const values = prop(attribsObj[attribId], 'values', []);
      values.push(valueId);
      attribsObj[attribId] = { values };
    });
  }

  let rangeAttribsObj;
  const urlRangeAttribFilters = prop(urlAttribs, 'range_attribs');
  if (urlRangeAttribFilters) {
    rangeAttribsObj = {};
    const attribs = urlRangeAttribFilters.split(',');
    attribs.map(attrib => {
      const [attribId, value] = attrib.split('|');
      const [min, max] = value.split('-');
      rangeAttribsObj[attribId] = { min, max };
    });
  }

  const otherAttribsWhitelist = [
    'new',
    'sale',
    'saleB2B',
    'saleout',
    'onlyAvailable',
    'ordered',
    'productCount',
  ];

  const otherAttribs = {};
  Object.keys(urlAttribs).map(key => {
    if (otherAttribsWhitelist.includes(key)) {
      otherAttribs[key] = urlAttribs[key];
    }
  });

  return {
    ...urlAttribs,
    attribs: attribsObj,
    rangeAttribs: rangeAttribsObj,
    activeRangeAttribs: urlRangeAttribFilters ? urlRangeAttribFilters : '',
    activeAttribs: urlAttribFilters ? urlAttribFilters : '',
    otherAttribs,
    q: prop(urlAttribs, 'q') ? prop(urlAttribs, 'q') : '',
  };
};
