import { FlexCol, TextP } from 'eshop-defaults';
import { rem } from 'polished';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

interface Props {
  id?: string;
  name?: string;
  label?: string;
  value: string | number | undefined;
  maxWidth?: number;
  options: Array<{
    name: string;
    value: string;
  }>;
  autoComplete?: string;
  defaultValue?: {
    name: string;
    value: string | undefined | number;
  };
  onChange: (e: any) => void;
  onBlur?: (e?: any) => void;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
}

const SearchableSelect = ({
  id,
  label,
  options,
  onChange,
  onBlur,
  required,
  value,
}: Props) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const inputRef = useRef<any>(null);

  useEffect(() => {
    if (value) {
      setSearchTerm(value as string);
    }
  }, [value]);

  const filteredOptions =
    searchTerm && searchTerm !== '-'
      ? options.filter(
          option =>
            option &&
            option.name &&
            option.name.toLowerCase().includes(searchTerm.toLowerCase()),
        )
      : options;

  const handleSelect = option => {
    setSearchTerm(option.name);
    setIsOpen(false);
    setSelectedIndex(-1);
    onChange(option);
  };

  const handleKeyDown = e => {
    if (e.key === 'ArrowDown') {
      setSelectedIndex(prev => Math.min(prev + 1, filteredOptions.length - 1));
    } else if (e.key === 'ArrowUp') {
      setSelectedIndex(prev => Math.max(prev - 1, 0));
    } else if (
      e.key === 'Enter' &&
      filteredOptions &&
      filteredOptions.length === 1
    ) {
      handleSelect(filteredOptions[0]);
    } else if (e.key === 'Enter' && selectedIndex >= 0) {
      handleSelect(filteredOptions[selectedIndex]);
    } else if (e.key === 'Escape') {
      setIsOpen(false);
    }
  };

  const handleReset = () => {
    setSearchTerm('');
    onChange('');
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
    setTimeout(() => {
      setIsOpen(true);
    }, 201);
  };

  return (
    <Wrapper>
      {label && (
        <SelectLabel for={`norms-input-${id}`} label={label}>
          {label}
          {required && <Required>{' *'}</Required>}
        </SelectLabel>
      )}
      <SelectWrapper>
        <Input
          type="text"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          onFocus={() => setIsOpen(true)}
          onBlur={() => {
            setTimeout(() => {
              setIsOpen(false);
            }, 200);
          }}
          onKeyDown={handleKeyDown}
          ref={inputRef}
        />
        <ResetButton onClick={handleReset}>
          <Icon src="/assets/zemplin/filterClose.svg" />
        </ResetButton>
        <Dropdown isOpen={isOpen}>
          {filteredOptions.map((option, index) => (
            <Option
              key={option.value}
              onClick={() => handleSelect(option)}
              isSelected={index === selectedIndex}
            >
              {option.name}
            </Option>
          ))}
        </Dropdown>
      </SelectWrapper>
    </Wrapper>
  );
};

const Icon = styled.img`
  width: ${rem(24)};
  height: ${rem(24)};
`;

const ResetButton = styled.button`
  background: transparent;
  border: 0;
  position: absolute;
  top: ${rem(11)};
  right: 10%;
  cursor: pointer;
`;

const Wrapper = styled(FlexCol)`
  width: 100%;
  color: #aaaaaa;
  position: relative;
  max-width: ${({ maxWidth }) => rem(maxWidth)};
  margin-right: ${({ marginRight }) => rem(marginRight)};
  margin-bottom: ${({ marginBottom }) => rem(marginBottom)};
  text-align: left;

  &:focus-within {
    color: ${props => props.theme.colors.btnPrimary};
  }

  ${({ theme }) => theme.mediab.l925`
    margin: 0;
    margin-right: ${({ tabletMarginRight }) => rem(tabletMarginRight)};
    margin-bottom:  ${({ tabletMarginBottom }) => rem(tabletMarginBottom)};
  `}
  
  ${({ theme }) => theme.mediab.s450`
    margin: 0;
    margin-right: ${({ mobileMarginRight }) => rem(mobileMarginRight)};
    margin-bottom:  ${({ mobileMarginBottom }) => rem(mobileMarginBottom)};
  `}

  ${({ theme }) => theme.mediab.s450`
    max-width: initial;
  `}
`;

// Styled components
const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Input = styled.input`
  width: 100%;
  cursor: pointer;
  height: ${rem(48)};
  background: transparent;
  border: ${({ theme }) => theme.borders.secondary};
  border-radius: ${rem(2)};
  color: ${props => props.theme.colors.textPrimary};
  font-size: ${rem(14)};
  padding: ${rem(8)} ${rem(64)} ${rem(8)} ${rem(16)};
  appearance: none;
  box-shadow: ${({ theme }) => theme.boxShadow.input};
  font-weight: 400;
  display: flex;
  align-items: center;

  background-image: url('/images/arrow-triangle.svg');
  background-position: 95% 50%;
  background-size: 8px 4px;
  background-repeat: no-repeat;

  & > option[value=''][disabled] {
    display: none;
  }

  &:invalid {
    color: ${({ theme }) => theme.colors.placeholder};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
    outline: none;
  }
`;

const Dropdown = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  width: 100%;
  background: white;
  border: 1px solid #ccc;
  border-top: none;
  max-height: 200px;
  overflow-y: auto;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  z-index: 1000;
`;

const Option = styled.li`
  padding: 10px;
  cursor: pointer;
  transition: background 0.2s;
  color: ${({ theme }) => theme.colors.textPrimary};
  text-align: left;

  &:hover {
    background: #f0f0f0;
  }
  ${({ isSelected }) => isSelected && `background: #ddd;`}
`;

const SelectLabel: any = styled(TextP)`
  font-size: ${rem(12)};
  font-family: ${({ theme }) => theme.font.tertiary};
  line-height: ${rem(20)};
  margin-bottom: ${rem(2)};
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 600;
  text-align: left;
`;

const Required = styled.span`
  font-size: ${rem(12)};
  font-family: ${({ theme }) => theme.font.tertiary};
  line-height: ${rem(20)};
  color: ${({ theme }) => theme.colors.stockCountUnavailable};
`;

export default SearchableSelect;
